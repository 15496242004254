export const statusMap = {
  1: '活跃',
  2: '禁用'
}

export const languageMap = {
  zh: '中文',
  en: '英文'
}

export const languageList = [
  {
    label: '中文',
    value: 'zh'
  },
  {
    label: '英文',
    value: 'en'
  }
]
