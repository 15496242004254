import { http, createConfig } from '@wagmi/vue';
import { bscTestnet, bsc } from '@wagmi/vue/chains';
import { getConnectorClient, getClient } from '@wagmi/core';
import { JsonRpcProvider } from 'ethers';
export const config = createConfig({
  chains: [bscTestnet, bsc],
  transports: {
    [bscTestnet.id]: http('https://bsc-testnet-dataseed.bnbchain.org'),
    [bsc.id]: http('https://bsc-dataseed.bnbchain.org'),
  },
});

export const getWalletClient = () => getConnectorClient(config);

export const getProvider = ({ chainId } = {}) => {
  const client = getClient(config, { chainId });
  if (!client) return;
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  return new JsonRpcProvider(transport.url, network);
};

export async function getSigner() {
  const { account, chain, transport } = await getWalletClient();
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}
