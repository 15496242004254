import { VueQueryPlugin } from '@tanstack/vue-query'
import { defineNuxtPlugin } from 'nuxt/app'
import { WagmiPlugin } from '@wagmi/vue';

import { config } from '../wagmi'


// TODO: Move to @wagmi/vue/nuxt nitro plugin
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(WagmiPlugin, { config }).use(VueQueryPlugin, {})
})