/*
 * @Author: harley
 * @Date: 2024-04-11 17:00:48
 * @LastEditors: harley
 * @Description:
 */
export const ipRulesMap = {
  1: '限制注册',
  2: '阻止账号注册',
  3: '阻止访问'
}
