import { default as icons_45pageNV4rZqGgoXMeta } from "/home/mastodon-nuxt/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.18.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as index9wtPDYq2NIMeta } from "/home/mastodon-nuxt/pages/about/index.vue?macro=true";
import { default as bMCXm7yInWNMeta } from "/home/mastodon-nuxt/pages/b.vue?macro=true";
import { default as indexGhDr9d8VKpMeta } from "/home/mastodon-nuxt/pages/blocks/index.vue?macro=true";
import { default as indexzWsGKaBYNFMeta } from "/home/mastodon-nuxt/pages/bookmarks/index.vue?macro=true";
import { default as indexMmvg2jRJD9Meta } from "/home/mastodon-nuxt/pages/confirm/index.vue?macro=true";
import { default as contractcuhT7nKxlwMeta } from "/home/mastodon-nuxt/pages/contract.ts?macro=true";
import { default as _91id_93O0q70QUNhvMeta } from "/home/mastodon-nuxt/pages/DM/Message/[id].vue?macro=true";
import { default as _91id_93ddzQyt9jjJMeta } from "/home/mastodon-nuxt/pages/embed/[acct]/status/[id].vue?macro=true";
import { default as _91_tag_93P8l3Qqa1YvMeta } from "/home/mastodon-nuxt/pages/explore/hashtag/[_tag].vue?macro=true";
import { default as indexW63RpjUCj3Meta } from "/home/mastodon-nuxt/pages/followRequests/index.vue?macro=true";
import { default as indexL7ogPZB1k5Meta } from "/home/mastodon-nuxt/pages/index.vue?macro=true";
import { default as _91id_93PFLmPxuns1Meta } from "/home/mastodon-nuxt/pages/lists/[id].vue?macro=true";
import { default as indexO0tZ6v6PoXMeta } from "/home/mastodon-nuxt/pages/lists/index.vue?macro=true";
import { default as _91_91toId_93_93Mme0eoYTIxMeta } from "/home/mastodon-nuxt/pages/Message/[[toId]].vue?macro=true";
import { default as indexOocZdoQsP4Meta } from "/home/mastodon-nuxt/pages/mutes/index.vue?macro=true";
import { default as list2k15jZ8jXzMeta } from "/home/mastodon-nuxt/pages/notifications/components/list.vue?macro=true";
import { default as notificationyJH31LMNGDMeta } from "/home/mastodon-nuxt/pages/notifications/components/notification.vue?macro=true";
import { default as indexsKQSvvOyXbMeta } from "/home/mastodon-nuxt/pages/notifications/index.vue?macro=true";
import { default as indexb53sCuh5inMeta } from "/home/mastodon-nuxt/pages/password/reset/index.vue?macro=true";
import { default as index8fueGPWtJ2Meta } from "/home/mastodon-nuxt/pages/public/common/index.vue?macro=true";
import { default as indexMPTyIxLgMrMeta } from "/home/mastodon-nuxt/pages/public/local/index.vue?macro=true";
import { default as registerzfiwBx2ldiMeta } from "/home/mastodon-nuxt/pages/register/components/register.vue?macro=true";
import { default as rulesSX57QNdxMJMeta } from "/home/mastodon-nuxt/pages/register/components/rules.vue?macro=true";
import { default as verifyEmailkDZJCR4btjMeta } from "/home/mastodon-nuxt/pages/register/components/verifyEmail.vue?macro=true";
import { default as index6hiyTg9PxNMeta } from "/home/mastodon-nuxt/pages/register/index.vue?macro=true";
import { default as _91_type_938BICBCwAjhMeta } from "/home/mastodon-nuxt/pages/Search/[_type].vue?macro=true";
import { default as indexWxEn6mabxbMeta } from "/home/mastodon-nuxt/pages/Search/index.vue?macro=true";
import { default as indexC5QfPiXDkMMeta } from "/home/mastodon-nuxt/pages/send/index.vue?macro=true";
import { default as _91id_939jSOxbxAMkMeta } from "/home/mastodon-nuxt/pages/settings/adminAccounts/detail/[id].vue?macro=true";
import { default as indexCbe0ZcXDtBMeta } from "/home/mastodon-nuxt/pages/settings/adminAccounts/index.vue?macro=true";
import { default as edityZTdDt9NyIMeta } from "/home/mastodon-nuxt/pages/settings/adminFilters/edit.vue?macro=true";
import { default as indexOfOH49WHt9Meta } from "/home/mastodon-nuxt/pages/settings/adminFilters/index.vue?macro=true";
import { default as newWmDNqgfYkKMeta } from "/home/mastodon-nuxt/pages/settings/adminFilters/new.vue?macro=true";
import { default as _91domain_93ChTj4cqN8VMeta } from "/home/mastodon-nuxt/pages/settings/adminInstances/detail/[domain].vue?macro=true";
import { default as index75XMVaafGuMeta } from "/home/mastodon-nuxt/pages/settings/adminInstances/index.vue?macro=true";
import { default as editGSlDHCC67NMeta } from "/home/mastodon-nuxt/pages/settings/adminRoles/edit.vue?macro=true";
import { default as indexWWoXsIdMpWMeta } from "/home/mastodon-nuxt/pages/settings/adminRoles/index.vue?macro=true";
import { default as newZvstksI9WQMeta } from "/home/mastodon-nuxt/pages/settings/adminRoles/new.vue?macro=true";
import { default as indexsl0tW26BydMeta } from "/home/mastodon-nuxt/pages/settings/dashboard/index.vue?macro=true";
import { default as indexzzrFupDLXgMeta } from "/home/mastodon-nuxt/pages/settings/emailDomainBlocks/index.vue?macro=true";
import { default as newpwE36y99Y4Meta } from "/home/mastodon-nuxt/pages/settings/emailDomainBlocks/new.vue?macro=true";
import { default as indextMI4LxJXQ6Meta } from "/home/mastodon-nuxt/pages/settings/export/index.vue?macro=true";
import { default as configpHcFzKWc2SMeta } from "/home/mastodon-nuxt/pages/settings/followRecommend/config.js?macro=true";
import { default as indexRRemGU9uM5Meta } from "/home/mastodon-nuxt/pages/settings/followRecommend/index.vue?macro=true";
import { default as index3R3g3mSMD7Meta } from "/home/mastodon-nuxt/pages/settings/import/index.vue?macro=true";
import { default as addivOZesBicYMeta } from "/home/mastodon-nuxt/pages/settings/ipRules/add.vue?macro=true";
import { default as confighJlxA5z9ZLMeta } from "/home/mastodon-nuxt/pages/settings/ipRules/config.js?macro=true";
import { default as indexLFvLpf2lueMeta } from "/home/mastodon-nuxt/pages/settings/ipRules/index.vue?macro=true";
import { default as indexdDspZA2e6nMeta } from "/home/mastodon-nuxt/pages/settings/nsq/index.vue?macro=true";
import { default as index3ldLBxxgJCMeta } from "/home/mastodon-nuxt/pages/settings/profile/index.vue?macro=true";
import { default as indexR1I0bAYmeAMeta } from "/home/mastodon-nuxt/pages/settings/relayStation/index.vue?macro=true";
import { default as newxi1VZDSlaZMeta } from "/home/mastodon-nuxt/pages/settings/relayStation/new.vue?macro=true";
import { default as indexyCpJyUbtIdMeta } from "/home/mastodon-nuxt/pages/settings/report/index.vue?macro=true";
import { default as indexqrn7t2bH6LMeta } from "/home/mastodon-nuxt/pages/settings/rule/index.vue?macro=true";
import { default as indexFK98St1wSuMeta } from "/home/mastodon-nuxt/pages/settings/serverSetup/index.vue?macro=true";
import { default as index7Kw9eWSWcoMeta } from "/home/mastodon-nuxt/pages/settings/subscription/index.vue?macro=true";
import { default as indexLQRPEB6LB4Meta } from "/home/mastodon-nuxt/pages/subscribed/index.vue?macro=true";
import { default as swap_proU41PJIjxaKMeta } from "/home/mastodon-nuxt/pages/swap_pro.vue?macro=true";
import { default as swapHAxMOcdwsQMeta } from "/home/mastodon-nuxt/pages/swap.vue?macro=true";
import { default as followeriRFuVSUjRjMeta } from "/home/mastodon-nuxt/pages/User/[acct]/follower.vue?macro=true";
import { default as followingL73sBVvctRMeta } from "/home/mastodon-nuxt/pages/User/[acct]/following.vue?macro=true";
import { default as indexW4wqProSaoMeta } from "/home/mastodon-nuxt/pages/User/[acct]/index.vue?macro=true";
import { default as _91id_93dONnfGl8ebMeta } from "/home/mastodon-nuxt/pages/User/[acct]/status/[id].vue?macro=true";
import { default as abiy4ZtWpnjzXMeta } from "/home/mastodon-nuxt/pages/wallet/abi.ts?macro=true";
import { default as contractDzV9KPLgo9Meta } from "/home/mastodon-nuxt/pages/wallet/contract.ts?macro=true";
import { default as indexda45dGiVV1Meta } from "/home/mastodon-nuxt/pages/wallet/index.vue?macro=true";
export default [
  {
    name: icons_45pageNV4rZqGgoXMeta?.name ?? "icons-page",
    path: icons_45pageNV4rZqGgoXMeta?.path ?? "/_icons",
    meta: { ...(icons_45pageNV4rZqGgoXMeta || {}), ...{"layout":"svg-sprite"} },
    alias: icons_45pageNV4rZqGgoXMeta?.alias || [],
    redirect: icons_45pageNV4rZqGgoXMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.18.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: index9wtPDYq2NIMeta?.name ?? "about",
    path: index9wtPDYq2NIMeta?.path ?? "/about",
    meta: index9wtPDYq2NIMeta || {},
    alias: index9wtPDYq2NIMeta?.alias || [],
    redirect: index9wtPDYq2NIMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: bMCXm7yInWNMeta?.name ?? "b",
    path: bMCXm7yInWNMeta?.path ?? "/b",
    meta: bMCXm7yInWNMeta || {},
    alias: bMCXm7yInWNMeta?.alias || [],
    redirect: bMCXm7yInWNMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/b.vue").then(m => m.default || m)
  },
  {
    name: indexGhDr9d8VKpMeta?.name ?? "blocks",
    path: indexGhDr9d8VKpMeta?.path ?? "/blocks",
    meta: indexGhDr9d8VKpMeta || {},
    alias: indexGhDr9d8VKpMeta?.alias || [],
    redirect: indexGhDr9d8VKpMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/blocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexzWsGKaBYNFMeta?.name ?? "bookmarks",
    path: indexzWsGKaBYNFMeta?.path ?? "/bookmarks",
    meta: indexzWsGKaBYNFMeta || {},
    alias: indexzWsGKaBYNFMeta?.alias || [],
    redirect: indexzWsGKaBYNFMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/bookmarks/index.vue").then(m => m.default || m)
  },
  {
    name: indexMmvg2jRJD9Meta?.name ?? "confirm",
    path: indexMmvg2jRJD9Meta?.path ?? "/confirm",
    meta: indexMmvg2jRJD9Meta || {},
    alias: indexMmvg2jRJD9Meta?.alias || [],
    redirect: indexMmvg2jRJD9Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: contractcuhT7nKxlwMeta?.name ?? "contract",
    path: contractcuhT7nKxlwMeta?.path ?? "/contract",
    meta: contractcuhT7nKxlwMeta || {},
    alias: contractcuhT7nKxlwMeta?.alias || [],
    redirect: contractcuhT7nKxlwMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/contract.ts").then(m => m.default || m)
  },
  {
    name: _91id_93O0q70QUNhvMeta?.name ?? "DM-Message-id",
    path: _91id_93O0q70QUNhvMeta?.path ?? "/DM/Message/:id()",
    meta: _91id_93O0q70QUNhvMeta || {},
    alias: _91id_93O0q70QUNhvMeta?.alias || [],
    redirect: _91id_93O0q70QUNhvMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/DM/Message/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ddzQyt9jjJMeta?.name ?? "embed-acct-status-id",
    path: _91id_93ddzQyt9jjJMeta?.path ?? "/embed/:acct()/status/:id()",
    meta: _91id_93ddzQyt9jjJMeta || {},
    alias: _91id_93ddzQyt9jjJMeta?.alias || [],
    redirect: _91id_93ddzQyt9jjJMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/embed/[acct]/status/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_tag_93P8l3Qqa1YvMeta?.name ?? "explore-hashtag-_tag",
    path: _91_tag_93P8l3Qqa1YvMeta?.path ?? "/explore/hashtag/:_tag()",
    meta: _91_tag_93P8l3Qqa1YvMeta || {},
    alias: _91_tag_93P8l3Qqa1YvMeta?.alias || [],
    redirect: _91_tag_93P8l3Qqa1YvMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/explore/hashtag/[_tag].vue").then(m => m.default || m)
  },
  {
    name: indexW63RpjUCj3Meta?.name ?? "followRequests",
    path: indexW63RpjUCj3Meta?.path ?? "/followRequests",
    meta: indexW63RpjUCj3Meta || {},
    alias: indexW63RpjUCj3Meta?.alias || [],
    redirect: indexW63RpjUCj3Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/followRequests/index.vue").then(m => m.default || m)
  },
  {
    name: indexL7ogPZB1k5Meta?.name ?? "index",
    path: indexL7ogPZB1k5Meta?.path ?? "/",
    meta: indexL7ogPZB1k5Meta || {},
    alias: indexL7ogPZB1k5Meta?.alias || [],
    redirect: indexL7ogPZB1k5Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PFLmPxuns1Meta?.name ?? "lists-id",
    path: _91id_93PFLmPxuns1Meta?.path ?? "/lists/:id()",
    meta: _91id_93PFLmPxuns1Meta || {},
    alias: _91id_93PFLmPxuns1Meta?.alias || [],
    redirect: _91id_93PFLmPxuns1Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: indexO0tZ6v6PoXMeta?.name ?? "lists",
    path: indexO0tZ6v6PoXMeta?.path ?? "/lists",
    meta: indexO0tZ6v6PoXMeta || {},
    alias: indexO0tZ6v6PoXMeta?.alias || [],
    redirect: indexO0tZ6v6PoXMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/lists/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91toId_93_93Mme0eoYTIxMeta?.name ?? "Message-toId",
    path: _91_91toId_93_93Mme0eoYTIxMeta?.path ?? "/Message/:toId?",
    meta: _91_91toId_93_93Mme0eoYTIxMeta || {},
    alias: _91_91toId_93_93Mme0eoYTIxMeta?.alias || [],
    redirect: _91_91toId_93_93Mme0eoYTIxMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/Message/[[toId]].vue").then(m => m.default || m)
  },
  {
    name: indexOocZdoQsP4Meta?.name ?? "mutes",
    path: indexOocZdoQsP4Meta?.path ?? "/mutes",
    meta: indexOocZdoQsP4Meta || {},
    alias: indexOocZdoQsP4Meta?.alias || [],
    redirect: indexOocZdoQsP4Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/mutes/index.vue").then(m => m.default || m)
  },
  {
    name: list2k15jZ8jXzMeta?.name ?? "notifications-components-list",
    path: list2k15jZ8jXzMeta?.path ?? "/notifications/components/list",
    meta: list2k15jZ8jXzMeta || {},
    alias: list2k15jZ8jXzMeta?.alias || [],
    redirect: list2k15jZ8jXzMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/notifications/components/list.vue").then(m => m.default || m)
  },
  {
    name: notificationyJH31LMNGDMeta?.name ?? "notifications-components-notification",
    path: notificationyJH31LMNGDMeta?.path ?? "/notifications/components/notification",
    meta: notificationyJH31LMNGDMeta || {},
    alias: notificationyJH31LMNGDMeta?.alias || [],
    redirect: notificationyJH31LMNGDMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/notifications/components/notification.vue").then(m => m.default || m)
  },
  {
    name: indexsKQSvvOyXbMeta?.name ?? "notifications",
    path: indexsKQSvvOyXbMeta?.path ?? "/notifications",
    meta: indexsKQSvvOyXbMeta || {},
    alias: indexsKQSvvOyXbMeta?.alias || [],
    redirect: indexsKQSvvOyXbMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexb53sCuh5inMeta?.name ?? "password-reset",
    path: indexb53sCuh5inMeta?.path ?? "/password/reset",
    meta: indexb53sCuh5inMeta || {},
    alias: indexb53sCuh5inMeta?.alias || [],
    redirect: indexb53sCuh5inMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: index8fueGPWtJ2Meta?.name ?? "public-common",
    path: index8fueGPWtJ2Meta?.path ?? "/public/common",
    meta: index8fueGPWtJ2Meta || {},
    alias: index8fueGPWtJ2Meta?.alias || [],
    redirect: index8fueGPWtJ2Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/public/common/index.vue").then(m => m.default || m)
  },
  {
    name: indexMPTyIxLgMrMeta?.name ?? "public-local",
    path: indexMPTyIxLgMrMeta?.path ?? "/public/local",
    meta: indexMPTyIxLgMrMeta || {},
    alias: indexMPTyIxLgMrMeta?.alias || [],
    redirect: indexMPTyIxLgMrMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/public/local/index.vue").then(m => m.default || m)
  },
  {
    name: registerzfiwBx2ldiMeta?.name ?? "register-components-register",
    path: registerzfiwBx2ldiMeta?.path ?? "/register/components/register",
    meta: registerzfiwBx2ldiMeta || {},
    alias: registerzfiwBx2ldiMeta?.alias || [],
    redirect: registerzfiwBx2ldiMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/register/components/register.vue").then(m => m.default || m)
  },
  {
    name: rulesSX57QNdxMJMeta?.name ?? "register-components-rules",
    path: rulesSX57QNdxMJMeta?.path ?? "/register/components/rules",
    meta: rulesSX57QNdxMJMeta || {},
    alias: rulesSX57QNdxMJMeta?.alias || [],
    redirect: rulesSX57QNdxMJMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/register/components/rules.vue").then(m => m.default || m)
  },
  {
    name: verifyEmailkDZJCR4btjMeta?.name ?? "register-components-verifyEmail",
    path: verifyEmailkDZJCR4btjMeta?.path ?? "/register/components/verifyEmail",
    meta: verifyEmailkDZJCR4btjMeta || {},
    alias: verifyEmailkDZJCR4btjMeta?.alias || [],
    redirect: verifyEmailkDZJCR4btjMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/register/components/verifyEmail.vue").then(m => m.default || m)
  },
  {
    name: index6hiyTg9PxNMeta?.name ?? "register",
    path: index6hiyTg9PxNMeta?.path ?? "/register",
    meta: index6hiyTg9PxNMeta || {},
    alias: index6hiyTg9PxNMeta?.alias || [],
    redirect: index6hiyTg9PxNMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: _91_type_938BICBCwAjhMeta?.name ?? "Search-_type",
    path: _91_type_938BICBCwAjhMeta?.path ?? "/Search/:_type()",
    meta: _91_type_938BICBCwAjhMeta || {},
    alias: _91_type_938BICBCwAjhMeta?.alias || [],
    redirect: _91_type_938BICBCwAjhMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/Search/[_type].vue").then(m => m.default || m)
  },
  {
    name: indexWxEn6mabxbMeta?.name ?? "Search",
    path: indexWxEn6mabxbMeta?.path ?? "/Search",
    meta: indexWxEn6mabxbMeta || {},
    alias: indexWxEn6mabxbMeta?.alias || [],
    redirect: indexWxEn6mabxbMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/Search/index.vue").then(m => m.default || m)
  },
  {
    name: indexC5QfPiXDkMMeta?.name ?? "send",
    path: indexC5QfPiXDkMMeta?.path ?? "/send",
    meta: indexC5QfPiXDkMMeta || {},
    alias: indexC5QfPiXDkMMeta?.alias || [],
    redirect: indexC5QfPiXDkMMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/send/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939jSOxbxAMkMeta?.name ?? "settings-adminAccounts-detail-id",
    path: _91id_939jSOxbxAMkMeta?.path ?? "/settings/adminAccounts/detail/:id()",
    meta: _91id_939jSOxbxAMkMeta || {},
    alias: _91id_939jSOxbxAMkMeta?.alias || [],
    redirect: _91id_939jSOxbxAMkMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminAccounts/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCbe0ZcXDtBMeta?.name ?? "settings-adminAccounts",
    path: indexCbe0ZcXDtBMeta?.path ?? "/settings/adminAccounts",
    meta: indexCbe0ZcXDtBMeta || {},
    alias: indexCbe0ZcXDtBMeta?.alias || [],
    redirect: indexCbe0ZcXDtBMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminAccounts/index.vue").then(m => m.default || m)
  },
  {
    name: edityZTdDt9NyIMeta?.name ?? "settings-adminFilters-edit",
    path: edityZTdDt9NyIMeta?.path ?? "/settings/adminFilters/edit",
    meta: edityZTdDt9NyIMeta || {},
    alias: edityZTdDt9NyIMeta?.alias || [],
    redirect: edityZTdDt9NyIMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminFilters/edit.vue").then(m => m.default || m)
  },
  {
    name: indexOfOH49WHt9Meta?.name ?? "settings-adminFilters",
    path: indexOfOH49WHt9Meta?.path ?? "/settings/adminFilters",
    meta: indexOfOH49WHt9Meta || {},
    alias: indexOfOH49WHt9Meta?.alias || [],
    redirect: indexOfOH49WHt9Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminFilters/index.vue").then(m => m.default || m)
  },
  {
    name: newWmDNqgfYkKMeta?.name ?? "settings-adminFilters-new",
    path: newWmDNqgfYkKMeta?.path ?? "/settings/adminFilters/new",
    meta: newWmDNqgfYkKMeta || {},
    alias: newWmDNqgfYkKMeta?.alias || [],
    redirect: newWmDNqgfYkKMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminFilters/new.vue").then(m => m.default || m)
  },
  {
    name: _91domain_93ChTj4cqN8VMeta?.name ?? "settings-adminInstances-detail-domain",
    path: _91domain_93ChTj4cqN8VMeta?.path ?? "/settings/adminInstances/detail/:domain()",
    meta: _91domain_93ChTj4cqN8VMeta || {},
    alias: _91domain_93ChTj4cqN8VMeta?.alias || [],
    redirect: _91domain_93ChTj4cqN8VMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminInstances/detail/[domain].vue").then(m => m.default || m)
  },
  {
    name: index75XMVaafGuMeta?.name ?? "settings-adminInstances",
    path: index75XMVaafGuMeta?.path ?? "/settings/adminInstances",
    meta: index75XMVaafGuMeta || {},
    alias: index75XMVaafGuMeta?.alias || [],
    redirect: index75XMVaafGuMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminInstances/index.vue").then(m => m.default || m)
  },
  {
    name: editGSlDHCC67NMeta?.name ?? "settings-adminRoles-edit",
    path: editGSlDHCC67NMeta?.path ?? "/settings/adminRoles/edit",
    meta: editGSlDHCC67NMeta || {},
    alias: editGSlDHCC67NMeta?.alias || [],
    redirect: editGSlDHCC67NMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminRoles/edit.vue").then(m => m.default || m)
  },
  {
    name: indexWWoXsIdMpWMeta?.name ?? "settings-adminRoles",
    path: indexWWoXsIdMpWMeta?.path ?? "/settings/adminRoles",
    meta: indexWWoXsIdMpWMeta || {},
    alias: indexWWoXsIdMpWMeta?.alias || [],
    redirect: indexWWoXsIdMpWMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminRoles/index.vue").then(m => m.default || m)
  },
  {
    name: newZvstksI9WQMeta?.name ?? "settings-adminRoles-new",
    path: newZvstksI9WQMeta?.path ?? "/settings/adminRoles/new",
    meta: newZvstksI9WQMeta || {},
    alias: newZvstksI9WQMeta?.alias || [],
    redirect: newZvstksI9WQMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/adminRoles/new.vue").then(m => m.default || m)
  },
  {
    name: indexsl0tW26BydMeta?.name ?? "settings-dashboard",
    path: indexsl0tW26BydMeta?.path ?? "/settings/dashboard",
    meta: indexsl0tW26BydMeta || {},
    alias: indexsl0tW26BydMeta?.alias || [],
    redirect: indexsl0tW26BydMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexzzrFupDLXgMeta?.name ?? "settings-emailDomainBlocks",
    path: indexzzrFupDLXgMeta?.path ?? "/settings/emailDomainBlocks",
    meta: indexzzrFupDLXgMeta || {},
    alias: indexzzrFupDLXgMeta?.alias || [],
    redirect: indexzzrFupDLXgMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/emailDomainBlocks/index.vue").then(m => m.default || m)
  },
  {
    name: newpwE36y99Y4Meta?.name ?? "settings-emailDomainBlocks-new",
    path: newpwE36y99Y4Meta?.path ?? "/settings/emailDomainBlocks/new",
    meta: newpwE36y99Y4Meta || {},
    alias: newpwE36y99Y4Meta?.alias || [],
    redirect: newpwE36y99Y4Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/emailDomainBlocks/new.vue").then(m => m.default || m)
  },
  {
    name: indextMI4LxJXQ6Meta?.name ?? "settings-export",
    path: indextMI4LxJXQ6Meta?.path ?? "/settings/export",
    meta: indextMI4LxJXQ6Meta || {},
    alias: indextMI4LxJXQ6Meta?.alias || [],
    redirect: indextMI4LxJXQ6Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/export/index.vue").then(m => m.default || m)
  },
  {
    name: configpHcFzKWc2SMeta?.name ?? "settings-followRecommend-config",
    path: configpHcFzKWc2SMeta?.path ?? "/settings/followRecommend/config",
    meta: configpHcFzKWc2SMeta || {},
    alias: configpHcFzKWc2SMeta?.alias || [],
    redirect: configpHcFzKWc2SMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/followRecommend/config.js").then(m => m.default || m)
  },
  {
    name: indexRRemGU9uM5Meta?.name ?? "settings-followRecommend",
    path: indexRRemGU9uM5Meta?.path ?? "/settings/followRecommend",
    meta: indexRRemGU9uM5Meta || {},
    alias: indexRRemGU9uM5Meta?.alias || [],
    redirect: indexRRemGU9uM5Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/followRecommend/index.vue").then(m => m.default || m)
  },
  {
    name: index3R3g3mSMD7Meta?.name ?? "settings-import",
    path: index3R3g3mSMD7Meta?.path ?? "/settings/import",
    meta: index3R3g3mSMD7Meta || {},
    alias: index3R3g3mSMD7Meta?.alias || [],
    redirect: index3R3g3mSMD7Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/import/index.vue").then(m => m.default || m)
  },
  {
    name: addivOZesBicYMeta?.name ?? "settings-ipRules-add",
    path: addivOZesBicYMeta?.path ?? "/settings/ipRules/add",
    meta: addivOZesBicYMeta || {},
    alias: addivOZesBicYMeta?.alias || [],
    redirect: addivOZesBicYMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/ipRules/add.vue").then(m => m.default || m)
  },
  {
    name: confighJlxA5z9ZLMeta?.name ?? "settings-ipRules-config",
    path: confighJlxA5z9ZLMeta?.path ?? "/settings/ipRules/config",
    meta: confighJlxA5z9ZLMeta || {},
    alias: confighJlxA5z9ZLMeta?.alias || [],
    redirect: confighJlxA5z9ZLMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/ipRules/config.js").then(m => m.default || m)
  },
  {
    name: indexLFvLpf2lueMeta?.name ?? "settings-ipRules",
    path: indexLFvLpf2lueMeta?.path ?? "/settings/ipRules",
    meta: indexLFvLpf2lueMeta || {},
    alias: indexLFvLpf2lueMeta?.alias || [],
    redirect: indexLFvLpf2lueMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/ipRules/index.vue").then(m => m.default || m)
  },
  {
    name: indexdDspZA2e6nMeta?.name ?? "settings-nsq",
    path: indexdDspZA2e6nMeta?.path ?? "/settings/nsq",
    meta: indexdDspZA2e6nMeta || {},
    alias: indexdDspZA2e6nMeta?.alias || [],
    redirect: indexdDspZA2e6nMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/nsq/index.vue").then(m => m.default || m)
  },
  {
    name: index3ldLBxxgJCMeta?.name ?? "settings-profile",
    path: index3ldLBxxgJCMeta?.path ?? "/settings/profile",
    meta: index3ldLBxxgJCMeta || {},
    alias: index3ldLBxxgJCMeta?.alias || [],
    redirect: index3ldLBxxgJCMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexR1I0bAYmeAMeta?.name ?? "settings-relayStation",
    path: indexR1I0bAYmeAMeta?.path ?? "/settings/relayStation",
    meta: indexR1I0bAYmeAMeta || {},
    alias: indexR1I0bAYmeAMeta?.alias || [],
    redirect: indexR1I0bAYmeAMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/relayStation/index.vue").then(m => m.default || m)
  },
  {
    name: newxi1VZDSlaZMeta?.name ?? "settings-relayStation-new",
    path: newxi1VZDSlaZMeta?.path ?? "/settings/relayStation/new",
    meta: newxi1VZDSlaZMeta || {},
    alias: newxi1VZDSlaZMeta?.alias || [],
    redirect: newxi1VZDSlaZMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/relayStation/new.vue").then(m => m.default || m)
  },
  {
    name: indexyCpJyUbtIdMeta?.name ?? "settings-report",
    path: indexyCpJyUbtIdMeta?.path ?? "/settings/report",
    meta: indexyCpJyUbtIdMeta || {},
    alias: indexyCpJyUbtIdMeta?.alias || [],
    redirect: indexyCpJyUbtIdMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/report/index.vue").then(m => m.default || m)
  },
  {
    name: indexqrn7t2bH6LMeta?.name ?? "settings-rule",
    path: indexqrn7t2bH6LMeta?.path ?? "/settings/rule",
    meta: indexqrn7t2bH6LMeta || {},
    alias: indexqrn7t2bH6LMeta?.alias || [],
    redirect: indexqrn7t2bH6LMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/rule/index.vue").then(m => m.default || m)
  },
  {
    name: indexFK98St1wSuMeta?.name ?? "settings-serverSetup",
    path: indexFK98St1wSuMeta?.path ?? "/settings/serverSetup",
    meta: indexFK98St1wSuMeta || {},
    alias: indexFK98St1wSuMeta?.alias || [],
    redirect: indexFK98St1wSuMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/serverSetup/index.vue").then(m => m.default || m)
  },
  {
    name: index7Kw9eWSWcoMeta?.name ?? "settings-subscription",
    path: index7Kw9eWSWcoMeta?.path ?? "/settings/subscription",
    meta: index7Kw9eWSWcoMeta || {},
    alias: index7Kw9eWSWcoMeta?.alias || [],
    redirect: index7Kw9eWSWcoMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/settings/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: indexLQRPEB6LB4Meta?.name ?? "subscribed",
    path: indexLQRPEB6LB4Meta?.path ?? "/subscribed",
    meta: indexLQRPEB6LB4Meta || {},
    alias: indexLQRPEB6LB4Meta?.alias || [],
    redirect: indexLQRPEB6LB4Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/subscribed/index.vue").then(m => m.default || m)
  },
  {
    name: swap_proU41PJIjxaKMeta?.name ?? "swap_pro",
    path: swap_proU41PJIjxaKMeta?.path ?? "/swap_pro",
    meta: swap_proU41PJIjxaKMeta || {},
    alias: swap_proU41PJIjxaKMeta?.alias || [],
    redirect: swap_proU41PJIjxaKMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/swap_pro.vue").then(m => m.default || m)
  },
  {
    name: swapHAxMOcdwsQMeta?.name ?? "swap",
    path: swapHAxMOcdwsQMeta?.path ?? "/swap",
    meta: swapHAxMOcdwsQMeta || {},
    alias: swapHAxMOcdwsQMeta?.alias || [],
    redirect: swapHAxMOcdwsQMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/swap.vue").then(m => m.default || m)
  },
  {
    name: followeriRFuVSUjRjMeta?.name ?? "User-acct-follower",
    path: followeriRFuVSUjRjMeta?.path ?? "/User/:acct()/follower",
    meta: followeriRFuVSUjRjMeta || {},
    alias: followeriRFuVSUjRjMeta?.alias || [],
    redirect: followeriRFuVSUjRjMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/User/[acct]/follower.vue").then(m => m.default || m)
  },
  {
    name: followingL73sBVvctRMeta?.name ?? "User-acct-following",
    path: followingL73sBVvctRMeta?.path ?? "/User/:acct()/following",
    meta: followingL73sBVvctRMeta || {},
    alias: followingL73sBVvctRMeta?.alias || [],
    redirect: followingL73sBVvctRMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/User/[acct]/following.vue").then(m => m.default || m)
  },
  {
    name: indexW4wqProSaoMeta?.name ?? "User-acct",
    path: indexW4wqProSaoMeta?.path ?? "/User/:acct()",
    meta: indexW4wqProSaoMeta || {},
    alias: indexW4wqProSaoMeta?.alias || [],
    redirect: indexW4wqProSaoMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/User/[acct]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dONnfGl8ebMeta?.name ?? "User-acct-status-id",
    path: _91id_93dONnfGl8ebMeta?.path ?? "/User/:acct()/status/:id()",
    meta: _91id_93dONnfGl8ebMeta || {},
    alias: _91id_93dONnfGl8ebMeta?.alias || [],
    redirect: _91id_93dONnfGl8ebMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/User/[acct]/status/[id].vue").then(m => m.default || m)
  },
  {
    name: abiy4ZtWpnjzXMeta?.name ?? "wallet-abi",
    path: abiy4ZtWpnjzXMeta?.path ?? "/wallet/abi",
    meta: abiy4ZtWpnjzXMeta || {},
    alias: abiy4ZtWpnjzXMeta?.alias || [],
    redirect: abiy4ZtWpnjzXMeta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/wallet/abi.ts").then(m => m.default || m)
  },
  {
    name: contractDzV9KPLgo9Meta?.name ?? "wallet-contract",
    path: contractDzV9KPLgo9Meta?.path ?? "/wallet/contract",
    meta: contractDzV9KPLgo9Meta || {},
    alias: contractDzV9KPLgo9Meta?.alias || [],
    redirect: contractDzV9KPLgo9Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/wallet/contract.ts").then(m => m.default || m)
  },
  {
    name: indexda45dGiVV1Meta?.name ?? "wallet",
    path: indexda45dGiVV1Meta?.path ?? "/wallet",
    meta: indexda45dGiVV1Meta || {},
    alias: indexda45dGiVV1Meta?.alias || [],
    redirect: indexda45dGiVV1Meta?.redirect,
    component: () => import("/home/mastodon-nuxt/pages/wallet/index.vue").then(m => m.default || m)
  }
]